<template>
  <section class="body-sign">
    <div class="center-sign">
      <a href="/" class="logo float-left">
        <img src="/img/logo-short-color.png" height="40" alt="Porto Admin" />
      </a>
      <div class="panel card-sign">
        <div class="card-title-sign mt-3 text-right">
          <h2 class="title text-uppercase font-weight-bold m-0"><i class="fas fa-user mr-1"></i> Trocar senha</h2>
        </div>
        <div class="card-body">
          <div class="alert alert-info">
            <p class="m-0">Digite sua nova senha e confirme para efetivar a troca.</p>
          </div>
          <form action="/auth/login" id="form" method="post">
            <div class="form-group mb-3">
              <label>Login</label>
              <div class="input-group">
                <input class="form-control form-control-lg" v-model="userData.Email" disabled="disabled" />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-user"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger"></span>
            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Senha</label>
              </div>
              <div class="input-group">
                <input class="form-control form-control-lg" v-model="userData.Senha" type="password" />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-lock"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{error && error}}</span>

            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Confirmação de senha</label>
              </div>
              <div class="input-group">
                <input class="form-control form-control-lg" v-model="userData.Confirmacao" type="password" />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-lock"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{error && error}}</span>

            </div>
            <div class="row">
              <div class="col text-right">
                <button type="submit" @click="trocar()" class="btn btn-primary mt-2" id="entrar" :disabled="loading"> {{loading ? 'Trocando...' : 'Trocar'}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p class="text-center text-muted mt-3 mb-3">&copy; Estoque Auto. Todos os direitos reservados.</p>
    </div>
  </section>
</template>

<script>
  // @ is an alias to /src
  import { trocar } from '@/services/user.service.js';

  export default {
    data() {
      return {
        userData: {
          Email: '',
          Senha: '',
          Confirmacao: '',
          Code: '',
        },
        error: '',
        loading: false
      };
    },
    mounted: function () {
      let that = this;
      this.$nextTick(function () {
        that.userData.Email = that.$route.params.email;
        that.userData.Code = that.$route.params.code;
      });
    },
    methods: {
      trocar: function () {
        this.loading = true;
        debugger;
        trocar(this.userData)
          .then(response => {
            const { msg, success } = response.data;
            alert(msg);
            if (success) {
              this.$router.push('/');
            }
            else {
              this.loading = false;
            }
          });
      }
    },
  }
</script>
<style scoped>

  .logo {
    margin-top: 1.7rem
  }
</style>

